/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface Muon {
  isOverdue: any;
  LoanId: number;
  Barcode: string;
  MemberId: number;
  MemberName: string;
  PhoneNumber: string;
  BookId: number;
  BookName: string;
  PublishYear: string;
  LoanDate: string;
  DueDate: string;
  ReturnDate: string;
  Location: string;
}

export interface MuonResponse {
  data: Muon[];
  pagination: Pagination;
}

export const getMuon = async (pagination: Pagination) => {
  const muon: Muon[] = await httpApi.get<APIResponse>('/loan').then((res) => res.data.data);
  console.log(muon);
  return {
    data: muon,
    pagination: { ...pagination, total: muon.length },
  };
};

export const getMuonById = async (Barcode: string) => {
  const barcode: Muon = await httpApi
    .get<APIResponse>(`/loan/getById`, {
      params: {
        Barcode: Barcode,
      },
    })
    .then((res) => res.data.data);
  console.log(barcode);
  return barcode;
};

export const postMuon = async (data: any): Promise<Muon> => {
  try {
    const response = await httpApi.post<APIResponse>(`/loan/store`, data);
    const postMuon: Muon = response.data.data;
    console.log(postMuon);
    return postMuon;
  } catch (error) {
    console.error('Error posting loan:', error);
    throw error;
  }
};
