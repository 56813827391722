import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ViTriTable } from '@app/components/tables/BasicTable/ViTriTable';
import './Pages.css';
const ViTri: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Vị trí Sách/Thiết bị"
                  footer="Thông tin vị trí Sách/Thiết bị"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />}>
                      Thêm Vị Trí
                    </Button>,
                  ]}
                />
                <ViTriTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Vị trí Sách/Thiết bị"
            footer="Thông tin vị trí Sách/Thiết bị"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />}>
                Thêm Vị Trí
              </Button>,
            ]}
          />
          <ViTriTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ViTri</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ViTri;
