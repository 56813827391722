/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface ViTri {
  LocationId: string;
  CategoryName: string;
  CategoryId: string;
  CategoryCode: string;
  BookCount: number;
  UpdatedAt: string;
  UpdatedBy: string;
  StatusName: string;
}

export interface ViTriResponse {
  data: ViTri[];
  pagination: Pagination;
}

export const getViTri = async (pagination: Pagination) => {
  const getvitri: ViTri[] = await httpApi.get<APIResponse>('/location').then((res) => res.data.data);
  return {
    data: getvitri,
    pagination: { ...pagination, total: getvitri.length },
  };
};

export const getViTriById = async (LocationId: string) => {
  const vitri: ViTri = await httpApi
    .get<APIResponse>(`/location/getById`, {
      params: {
        LocationId: LocationId,
      },
    })
    .then((res) => res.data.data);
  console.log(vitri);
  return vitri;
};

export const postViTri = async (data: any): Promise<ViTri> => {
  try {
    const response = await httpApi.post<APIResponse>(`/location/store`, data);
    const postViTri: ViTri = response.data.data;
    console.log(postViTri);
    return postViTri;
  } catch (error) {
    console.error('Error posting loan:', error);
    throw error;
  }
};
