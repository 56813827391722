import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined } from '@ant-design/icons';
import { HeaderSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { Button, DatePicker, Form, Input, Modal, Select, Table, Upload } from 'antd';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const TonKhoTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getBasicTableData(pagination).then((res) => {
        if (isMounted.current) {
          setTableData({ data: res.data, pagination: res.pagination, loading: false });
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.IDsach'),
      dataIndex: 'idsach',
      width: '10%',
    },
    {
      title: t('common.tensach'),
      dataIndex: 'tensach',
      width: '15%',
    },
    {
      title: t('common.tacgia'),
      dataIndex: 'tacgia',
      width: '15%',
    },
    {
      title: t('common.tendanhmuc'),
      dataIndex: 'tendanhmuc',
      width: '15%',
    },
    {
      title: t('common.soluongkhadung'),
      dataIndex: 'soluongkhadung',
      width: '10%',
    },
    {
      title: t('common.soluongdangmuon'),
      dataIndex: 'soluongdangmuon',
      width: '10%',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <BaseSpace>
            <BaseButton type="ghost" onClick={showModal}>
              <EyeOutlined />
            </BaseButton>
            <BaseButton type="default" danger onClick={() => handleDeleteRow(record.key)}>
              <LockOutlined />
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsModalVisible(false);
  };

  const modalChitiet = (
    <Modal title="Chi Tiết Tồn Kho" visible={isModalVisible} onCancel={handleCancel} footer={null} style={{ top: 10 }}>
      <Form form={form} layout="inline" onFinish={handleSubmit}>
        <Form.Item
          style={{ width: '30%', marginBottom: '10px' }}
          label="ID Sách"
          name="bookId"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '60%', marginBottom: '10px' }}
          label="Tên Sách"
          name="bookName"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '45%', marginBottom: '10px' }}
          label="Tác giả"
          name="author"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '25%', marginBottom: '10px' }}
          label="Danh mục"
          name="category"
          rules={[{ required: true, message: 'Vui lòng chọn danh mục!' }]}
        >
          <Select>
            <Select.Option value="CNTT">CNTT</Select.Option>
            <Select.Option value="KhoaHoc">Khoa Học</Select.Option>
            <Select.Option value="VanHoc">Văn Học</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '19%', marginBottom: '10px' }}
          label="Vị trí"
          name="location"
          rules={[{ required: true, message: 'Vui lòng chọn vị trí!' }]}
        >
          <Select>
            <Select.Option value="A4">A4</Select.Option>
            <Select.Option value="B5">B5</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '45%', marginBottom: '10px' }}
          label="Số lượng khả dụng"
          name="availableQuantity"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          style={{ width: '45%', marginBottom: '40px' }}
          label="Số lượng đang mượn"
          name="borrowedQuantity"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item style={{ width: '100%', borderTop: '1px solid #d9d9d9' }}>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{ width: '30%', marginLeft: '70%', marginTop: '10px' }}
          >
            <EditOutlined /> Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <HeaderSearch />
      </div>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
