import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined } from '@ant-design/icons';
import { HeaderSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table, Upload } from 'antd';
import { getViTri, getViTriById, ViTri } from '@app/api/vitri.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { Loading } from '@app/components/common/Loading/Loading';

export const ViTriTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: ViTri[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedViTri, setselectedViTri] = useState<ViTri | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getViTri({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setTableData({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const columns: ColumnsType<ViTri> = [
    {
      title: t('common.idvitri'),
      dataIndex: 'LocationId',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.iddanhmuc'),
      dataIndex: 'CategoryId',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.soluongsach'),
      dataIndex: 'BookCount',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.capnhatganday'),
      dataIndex: 'UpdatedAt',
      width: '15%',
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
      align: 'center',
    },
    {
      title: t('common.nguoicapnhat'),
      dataIndex: 'UpdatedBy',
      width: '15%',
    },
    {
      title: t('common.tinhtrang'),
      dataIndex: 'StatusName',
      width: '15%',
      align: 'center',
      render: (text) => (
        <span
          style={{
            backgroundColor: text === 'Hoạt động' ? '#2fb350' : '#ffcc00',
            padding: '5px 10px',
            borderRadius: '10px',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return (
          <BaseSpace>
            <BaseButton type="ghost" onClick={() => showModal(record.LocationId)}>
              <EyeOutlined />
            </BaseButton>
            <BaseButton type="default" danger>
              <LockOutlined />
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const showModal = async (LocationId: string) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const vitri = await getViTriById(LocationId);
      setselectedViTri(vitri);
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setselectedViTri(null);
  };

  const modalChitiet = (
    <Modal
      title="Chi Tiết Vị Trí"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedViTri ? (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="ID Vị Trí" name="LocationId" initialValue={selectedViTri.LocationId}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="ID Danh mục" name="CategoryId" initialValue={selectedViTri.CategoryId}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Số lượng sách" name="BookCount" initialValue={selectedViTri.BookCount}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = tableData.data.filter(
    (item) =>
      String(item.LocationId).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.CategoryId).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <InputSearch
          style={{ width: '350px', marginRight: '10px' }}
          placeholder={t('Tìm kiếm theo ID, Họ tên')}
          onChange={handleSearch}
        />
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
