import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DanhMucTable } from '@app/components/tables/BasicTable/DanhMucTable';
import './Pages.css';
const DanhMuc: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Danh mục Sách/Thiết bị"
                  footer="Thông tin danh mục Sách/Thiết bị"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />}>
                      Thêm Danh Mục
                    </Button>,
                  ]}
                />
                <DanhMucTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Danh mục Sách/Thiết bị"
            footer="Thông tin danh mục Sách/Thiết bị"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />}>
                Thêm Danh Mục
              </Button>,
            ]}
          />
          <DanhMucTable />
        </div>{' '}
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>DanhMuc</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default DanhMuc;
