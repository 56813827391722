import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface ThongKe {
  TotalBook: number;
  TotalBookAvailable: number;
  TotalBookOnLoan: number;
  TotalMember: number;
}

export interface ThongKeResponse {
  data: ThongKe;
}

export const getThongKe = async (): Promise<ThongKe> => {
  const thongke: ThongKe = await httpApi.get<ThongKeResponse>('/dashboard/fast-stats').then((res) => res.data.data);
  return thongke;
};

export interface NewAdd {
  MemberId: number;
  MemberName: string;
  MemberType: string;
  MemberSinceDate: string;
}

export interface NewAddResponse {
  data: NewAdd[];
  pagination: Pagination;
}

export const getNewAdd = async (pagination: Pagination): Promise<NewAddResponse> => {
  const newadd: NewAdd[] = await httpApi.get<APIResponse>('/dashboard/new-loaners').then((res) => res.data.data);
  console.log(newadd);
  return {
    data: newadd,
    pagination: { ...pagination, total: newadd.length },
  };
};

export interface OverDue {
  DueDate: string;
  MemberId: string;
  MemberName: string;
  PhoneNumber: string;
}

export interface OverDueResponse {
  data: OverDue[];
  pagination: Pagination;
}

export const getOverDue = async (pagination: Pagination): Promise<OverDueResponse> => {
  const overdue: OverDue[] = await httpApi.get<APIResponse>('/dashboard/overdue-members').then((res) => res.data.data);
  console.log(overdue);
  return {
    data: overdue,
    pagination: { ...pagination, total: overdue.length },
  };
};
