/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface User {
  UserId: number;
  Username: string;
  RealName: string;
  PhoneNumber: string;
  UpdatedAt: string;
  UpdatedBy: string;
  IsLocked: number;
}

export interface UserResponse {
  data: User[];
  pagination: Pagination;
}

export const getUsers = async (pagination: Pagination) => {
  const users: User[] = await httpApi.get<APIResponse>('/user').then((res) => res.data.data);
  return {
    data: users,
    pagination: { ...pagination, total: users.length },
  };
};
