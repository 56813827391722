/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeOutlined, LockOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { getMember, getMemberById, Member } from '@app/api/thanhvien.api';
import { Loading } from '@app/components/common/Loading/Loading';

export const ThanhVienTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Member[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [showAll, setShowAll] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<string>('all');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getMember({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setTableData({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: any) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.MemberId !== rowId),
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = tableData.data
    .filter((item) => (showAll ? true : selectedType === 'all' || item.MemberTypeName === selectedType))
    .filter(
      (item) =>
        String(item.MemberId).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.MemberName).toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const columns: ColumnsType<Member> = [
    {
      title: t('common.name'),
      dataIndex: 'MemberName',
    },
    {
      title: 'SĐT',
      dataIndex: 'PhoneNumber',
      align: 'center',
    },
    {
      title: 'Số lần mượn',
      dataIndex: 'BorrowedBooks',
      align: 'center',
      width: '10%',
    },
    {
      title: t('common.tinhtrang'),
      dataIndex: 'IsLocked',
      align: 'center',
      width: '15%',
      render: (IsLocked) => (
        <span
          style={{ backgroundColor: IsLocked > 0 ? '#2fb350' : '#ffcc00', padding: '5px 10px', borderRadius: '10px' }}
        >
          {IsLocked > 0 ? 'Đang mượn sách' : 'Không mượn sách'}
        </span>
      ),
    },
    {
      title: t('common.dangmuon'),
      dataIndex: 'BorrowingBooks',
      align: 'center',
      width: '10%',
    },
    {
      title: t('common.phanloai'),
      dataIndex: 'MemberTypeName',
      width: '10%',
      align: 'center',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton type="ghost" onClick={() => showModal(record.MemberId)}>
            <EyeOutlined />
          </BaseButton>
          <BaseButton type="default" danger onClick={() => handleDeleteRow(record.MemberId)}>
            <LockOutlined />
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  const modalColumns: ColumnsType<Member['LoanDetails'][number]> = [
    {
      title: '#',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: '5%',
    },
    {
      title: 'ID Sách',
      dataIndex: 'BookId',
      align: 'center',
    },
    {
      title: 'Thời gian mượn',
      dataIndex: 'LoanDate',
      align: 'center',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    {
      title: 'Thời gian trả',
      dataIndex: 'RetunDate',
      align: 'center',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
  ];

  const showModal = async (MemberId: string) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const Memberid = await getMemberById(MemberId);
      setSelectedMember(Memberid);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedMember(null);
  };

  const formatDateToVN = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN');
  };

  const modalChitiet = (
    <Modal
      title="Chi Tiết"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedMember ? (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Họ và Tên" name="MemberName" initialValue={selectedMember.MemberName}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ID Thành viên" name="MemberId" initialValue={selectedMember.MemberId}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Số điện thoại" name="PhoneNumber" initialValue={selectedMember.PhoneNumber}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email" name="Email" initialValue={selectedMember.Email}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Phân loại" name="MemberTypeName" initialValue={selectedMember.MemberTypeName}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ngày đăng ký" name="CreatedAt" initialValue={formatDateToVN(selectedMember.CreatedAt)}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Số sách đã mượn" name="BorrowedBooks" initialValue={selectedMember.BorrowedBooks}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Đang mượn" name="BorrowingBooks" initialValue={selectedMember.BorrowingBooks}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Chi tiết mượn sách" style={{ marginBottom: '20px' }}>
            <BaseTable columns={modalColumns} dataSource={selectedMember?.LoanDetails || []} pagination={false} />
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleShowAll = () => {
    setShowAll(true);
    setSelectedType('all');
  };

  const handleShowType = (value: string) => {
    setShowAll(false);
    setSelectedType(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <BaseButton onClick={handleShowAll}>Tổng thành viên ({filteredData.length})</BaseButton>
        <Select defaultValue="Phân loại" style={{ width: 120, marginLeft: '10px' }} onChange={handleShowType}>
          <Select.Option value="all">Phân loại</Select.Option>
          <Select.Option value="Học sinh / Sinh viên">Hs/Sv</Select.Option>
          <Select.Option value="Giáo viên">Gv</Select.Option>
        </Select>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Họ tên')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
