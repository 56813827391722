/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeOutlined, LockOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Image, Input, Modal, Row, Select } from 'antd';
import { Book, getBooks, getBookById } from '@app/api/book.api';
import { Loading } from '@app/components/common/Loading/Loading';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import TextArea from 'antd/lib/input/TextArea';

export const ThongTinTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Book[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');
  const [selectedLocation, setSelectedLocation] = useState<string>('Default');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getBooks({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const books = res.data;

        const categories = Array.from(new Set(books.map((book) => book.Category)));
        const locations = Array.from(new Set(books.map((book) => book.Location)));

        setCategoryOptions(categories);
        setLocationOptions(locations);

        setTableData({ data: books, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: any) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.Barcode !== rowId),
    }));
  };

  const showModal = async (Barcode: any) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const book = await getBookById(Barcode);
      setSelectedBook(book);
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBook(null);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsModalVisible(false);
  };

  const modalChitiet = (
    <Modal
      title="Chi Tiết"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedBook ? (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Ảnh bìa sách" name="Thumbnail">
                <Image src={selectedBook.Thumbnail} alt="Không có ảnh" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Barcode" name="Barcode" initialValue={selectedBook.Barcode}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Ngày nhập sách" name="PublishYear" initialValue={selectedBook.PublishYear}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tên sách" name="BookName" initialValue={selectedBook.BookName}>
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  disabled
                  style={{ backgroundColor: '#EEEEEE', color: 'black' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tác giả" name="Authors" initialValue={selectedBook.Authors.join(', ')}>
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  disabled
                  style={{ backgroundColor: '#EEEEEE', color: 'black' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="NXB" name="PublisherName" initialValue={selectedBook.PublisherName}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Năm xuất bản" name="PublishYear" initialValue={selectedBook.PublishYear}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Danh mục" name="Category" initialValue={selectedBook.Category}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Vị trí" name="Location" initialValue={selectedBook.Location}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tình trạng sách"
                name="Available"
                initialValue={selectedBook.Available ? 'Còn sách' : 'Đang mượn'}
              >
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Thời hạn mượn" name="loanDuration">
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Ghi chú" name="ghichu">
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  disabled
                  style={{ backgroundColor: '#EEEEEE', color: 'black' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="CCCD" name="cccd">
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tên người mượn" name="borrowerName">
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Thời gian mượn" name="loanTime">
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const columns: ColumnsType<Book> = [
    {
      title: t('Barcode'),
      dataIndex: 'Barcode',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.tensach'),
      dataIndex: 'BookName',
    },
    {
      title: t('common.namxb'),
      dataIndex: 'PublishYear',
      width: '10%',
      align: 'center',
    },
    {
      title: t('Danh mục'),
      dataIndex: 'Category',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.vitri'),
      dataIndex: 'Location',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.tinhtrang'),
      dataIndex: 'Available',
      width: '15%',
      align: 'center',
      render: (available) => (
        <span style={{ backgroundColor: available ? '#2fb350' : '#ffcc00', padding: '5px 10px', borderRadius: '10px' }}>
          {available ? 'Còn sách' : 'Đang mượn'}
        </span>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <BaseSpace>
            <BaseButton type="ghost" onClick={() => showModal(record.Barcode)}>
              <EyeOutlined />
            </BaseButton>
            <BaseButton type="default" danger onClick={() => handleDeleteRow(record.Barcode)}>
              <LockOutlined />
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const filteredData = tableData.data.filter((item) => {
    const matchesSearchTerm =
      String(item.Barcode).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.BookName).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.Location).includes(searchTerm);

    const matchesCategory = selectedCategory === 'Default' || item.Category === selectedCategory;

    const matchesLocation = selectedLocation === 'Default' || item.Location === selectedLocation;

    return matchesSearchTerm && matchesCategory && matchesLocation;
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <div
          style={{
            marginRight: 'auto',
          }}
        >
          <Select
            defaultValue="Danh mục"
            style={{ width: 170, borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={handleCategoryChange}
          >
            <Select.Option value="Default">Danh mục</Select.Option>
            {categoryOptions.map((category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>

          <Select
            placeholder="Vị trí"
            style={{ width: 130, marginLeft: '10px', borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={handleLocationChange}
          >
            <Select.Option value="Default">Vị trí</Select.Option>
            {locationOptions.map((location) => (
              <Select.Option key={location} value={location}>
                {location}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Tên sách, Vị trí')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
