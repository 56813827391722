/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface DanhMuc {
  CategoryId: number;
  CategoryCode: string;
  CategoryName: string;
  BookCount: number;
  Locations: [
    {
      LocationId: string;
      LocationName: string;
    },
  ];
  UpdatedAt: string;
  UpdatedBy: string;
}

export interface DanhMucResponse {
  data: DanhMuc[];
  pagination: Pagination;
}

export const getDanhMuc = async (pagination: Pagination) => {
  const getvitri: DanhMuc[] = await httpApi.get<APIResponse>('/category').then((res) => res.data.data);
  return {
    data: getvitri,
    pagination: { ...pagination, total: getvitri.length },
  };
};

export const getDanhMucById = async (CategoryId: number) => {
  const vitri: DanhMuc = await httpApi
    .get<APIResponse>(`/category/getById`, {
      params: {
        CategoryId: CategoryId,
      },
    })
    .then((res) => res.data.data);
  console.log(vitri);
  return vitri;
};

export const postDanhMuc = async (data: any): Promise<DanhMuc> => {
  try {
    const response = await httpApi.post<APIResponse>(`/category/store`, data);
    const postViTri: DanhMuc = response.data.data;
    console.log(postViTri);
    return postViTri;
  } catch (error) {
    console.error('Error posting loan:', error);
    throw error;
  }
};
