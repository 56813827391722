/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface Book {
  Barcode: string;
  BookName: string;
  PublishYear: string;
  Category: string;
  Location: string;
  Available: boolean;

  PublisherName: string;
  Authors: string[];

  ISBN: string;
  AuthorName: string;
  Thumbnail: string;
  CategoryId: string;
  LocationId: string;
  Notes: string;
}

export interface BookResponse {
  data: Book[];
  pagination: Pagination;
}

export const getBooks = async (pagination: Pagination) => {
  const books: Book[] = await httpApi.get<APIResponse>('/book').then((res) => res.data.data);
  console.log(books);
  return {
    data: books,
    pagination: { ...pagination, total: books.length },
  };
};

export const getBookById = async (Barcode: string) => {
  const book: Book = await httpApi
    .get<APIResponse>(`/book/getByBarcode`, {
      params: {
        Barcode: Barcode,
      },
    })
    .then((res) => res.data.data);
  console.log(book);
  return book;
};

export const postBook = async (data: any): Promise<Book> => {
  try {
    const response = await httpApi.post<APIResponse>(`/book/store-items`, data);
    const postBook: Book = response.data.data;
    console.log(postBook);
    return postBook;
  } catch (error) {
    console.error('Error posting loan:', error);
    throw error;
  }
};

export const postAddBook = async (data: FormData, p0: { headers: { 'Content-Type': string } }): Promise<Book> => {
  try {
    const response = await httpApi.post<APIResponse>(`/book/store-biblio`, data, p0);
    const postBook: Book = response.data.data;
    console.log(postBook);
    return postBook;
  } catch (error) {
    console.error('Error posting book:', error);
    throw error;
  }
};
