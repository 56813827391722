import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { HeaderSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { CalendarOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const LichSuMuonTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getBasicTableData(pagination).then((res) => {
        if (isMounted.current) {
          setTableData({ data: res.data, pagination: res.pagination, loading: false });
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
    },
    {
      title: t('common.idsach'),
      dataIndex: 'idsach',
    },
    {
      title: t('common.thoigianmuon'),
      dataIndex: 'thoigianmuon',
      align: 'center',
    },
    {
      title: t('common.tinhtrang'),
      dataIndex: 'tinhtrang',
      align: 'center',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      align: 'center',
      width: '10%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={() => {
                notificationController.info({ message: t('tables.inviteMessage', { name: record.name }) });
              }}
            >
              <EyeOutlined />
            </BaseButton>
            <BaseButton type="default" danger onClick={() => handleDeleteRow(record.key)}>
              <EditOutlined />
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '0 20px 20px 20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <RangePicker
          suffixIcon={<CalendarOutlined style={{ color: '#000', fontSize: '18px' }} />}
          style={{ borderRadius: '8px', border: '1px solid #d9d9d9', padding: '5px 10px' }}
        />
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <HeaderSearch />
          <BaseButton style={{ marginLeft: '10px' }}>{t('tables.datra')}</BaseButton>
          <BaseButton style={{ marginLeft: '10px' }}>{t('tables.chuatra')}</BaseButton>
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    </div>
  );
};
