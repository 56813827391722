/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PlusOutlined } from '@ant-design/icons';
import { ThanhVienTable } from '@app/components/tables/BasicTable/ThanhVienTable';
import { Button, PageHeader, Modal, Form, Input, DatePicker, Table, Row, Col, message, Select } from 'antd';
import './Pages.css';
import { postMember } from '@app/api/thanhvien.api';
import { number } from 'echarts';
import TextArea from 'antd/lib/input/TextArea';

const ThanhVien: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const generateUniqueBarcode = (): string => {
    const numberPart = Math.floor(Math.random() * 100000)
      .toString()
      .padStart(10, '0');

    return `${numberPart}`;
  };

  const onFinish = async (values: any) => {
    try {
      const uniqueBarcode = generateUniqueBarcode();

      const transformedValues = {
        MemberName: values.MemberName,
        MemberId: values.MemberId,
        Barcodes: uniqueBarcode,
        Gender: Number(values.Gender),
        BirthDate: values.BirthDate ? values.BirthDate.format('YYYY-MM-DD') : null,
        MemberAddress: values.MemberAddress,
        MemberEmail: values.MemberEmail,
        MemberPhone: values.MemberPhone,
        MemberType: values.MemberType,
      };

      console.log('Received values from form: ', transformedValues);
      const response = await postMember(transformedValues);
      setIsModalVisible(false);
      form.resetFields();
      console.log('API response:', response);
      message.success('Danh mục sách đã được thêm thành công.');
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const modalThem = (
    <Modal
      title="Thêm Thành Viên"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Họ và Tên"
              name="MemberName"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input style={{ fontWeight: 'normal' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="ID Thành viên"
              name="MemberId"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Gới tính" name="Gender" rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}>
              <Select>
                <Select.Option value="1">Nam</Select.Option>
                <Select.Option value="0">Nữ</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sinh nhật"
              name="BirthDate"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Địa chỉ"
              name="MemberAddress"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <TextArea autoSize={{ minRows: 1, maxRows: 8 }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Số điện thoại"
              name="MemberPhone"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Email"
              name="MemberEmail"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phân loại"
              name="MemberType"
              rules={[{ required: true, message: 'Vui lòng chọn phân loại!' }]}
            >
              <Select>
                <Select.Option value="Học sinh / Sinh viên">Học sinh / Sinh viên</Select.Option>
                <Select.Option value="Giáo viên">Giáo viên</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
            <PlusOutlined /> Thêm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Thành viên"
                  footer="Thông tin thành viên của thư viện"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm Thành Viên
                    </Button>,
                  ]}
                />
                <ThanhVienTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Thành viên"
            footer="Thông tin thành viên của thư viện"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm Thành Viên
              </Button>,
            ]}
          />
          <ThanhVienTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ThanhVien</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default ThanhVien;
