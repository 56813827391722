/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, PageHeader, Tabs, Modal, Form, Input, DatePicker, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TabPane from 'antd/lib/tabs/TabPane';
import './Pages.css';
import { ThongTinMuonTable } from '@app/components/tables/BasicTable/ThongTinMuonTable';
import { LichSuMuonTable } from '@app/components/tables/BasicTable/LichSuMuonTable';
import { ThatLacTable } from '@app/components/tables/BasicTable/ThatLacTable';
import { postMuon } from '@app/api/muon.api';

const MuonSach: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const generateUniqueBarcode = (): string => {
    const prefix = 'GTLK';
    const numberPart = Math.floor(Math.random() * 100000)
      .toString()
      .padStart(5, '0');
    const suffix = 'OTE';

    return `${prefix}${numberPart}${suffix}`;
  };

  const onFinish = async (values: any) => {
    try {
      const uniqueBarcode = generateUniqueBarcode();

      const transformedValues = {
        ...values,
        MemberId: values.MemberId,
        LoanDate: values.LoanDate ? values.LoanDate.format('YYYY-MM-DD') : null,
        DueDate: values.DueDate ? values.DueDate.format('YYYY-MM-DD') : null,
        Barcodes: [values.Barcode],
      };

      console.log('Received values from form: ', transformedValues);
      const response = await postMuon(transformedValues);
      setIsModalVisible(false);
      form.resetFields();
      console.log('API response:', response);
      message.success('Danh mục sách đã được thêm thành công.');
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const modalThem = (
    <Modal
      title="Thêm Người Mượn"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Họ và Tên" name="MemberName">
              <Input style={{ fontWeight: 'normal' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Mã Thành Viên"
              name="MemberId"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Số điện thoại"
              name="PhoneNumber"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Barcode Sách được mượn"
              name="Barcode"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Vị trí" name="Location">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ngày mượn" name="LoanDate">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ngày Trả" name="DueDate" rules={[{ required: true, message: 'Vui lòng chọn ngày trả!' }]}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
            <PlusOutlined /> Thêm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol>
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>
            <div className="container">
              <PageHeader
                className="page-header"
                title="Thông tin Mượn Sách/Thiết bị"
                footer="Thông tin chi tiết của người mượn"
                extra={[
                  <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                    Thêm Người Mượn
                  </Button>,
                ]}
              />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Thông tin mượn sách" key="1">
                  <ThongTinMuonTable />
                </TabPane>
                <TabPane tab="Lịch sử mượn sách" key="2">
                  <LichSuMuonTable />
                </TabPane>
                <TabPane tab="Quản lý thất lạc" key="3">
                  <ThatLacTable />
                </TabPane>
              </Tabs>
            </div>
          </BaseCol>
        </BaseRow>
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Thông tin Mượn Sách/Thiết bị"
            footer="Thông tin chi tiết của người mượn"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm Người Mượn
              </Button>,
            ]}
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin mượn sách" key="1">
              <ThongTinMuonTable />
            </TabPane>
            <TabPane tab="Lịch sử mượn sách" key="2">
              <LichSuMuonTable />
            </TabPane>
            <TabPane tab="Quản lý thất lạc" key="3">
              <ThatLacTable />
            </TabPane>
          </Tabs>
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Muon</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default MuonSach;
