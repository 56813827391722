/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ThanhVienTable } from '@app/components/tables/BasicTable/ThanhVienTable';
import { Button, PageHeader, Modal, Form, Input, Select, message, Row, Col } from 'antd';
import './Pages.css';
import { NhapSachTable } from '@app/components/tables/BasicTable/NhapSachTable';
import { postBook } from '@app/api/book.api';

const NhapSach: React.FC = () => {
  const { isDesktop } = useResponsive();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookList, setBookList] = useState([{ id: 1, barcode: '' }]);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setBookList([{ id: 1, barcode: '' }]);
  };

  const onFinish = async (values: any) => {
    try {
      const data = {
        Items: [
          {
            Barcode: bookList.map((book) => book.barcode),
            BookId: values.BookId,
            LocationId: values.LocationId,
            BookName: values.BookName,
          },
        ],
      };

      console.log('Received values from form: ', data);

      const response = await postBook(data);
      console.log('Response:', response);

      setIsModalVisible(false);
      form.resetFields();
      setBookList([{ id: 1, barcode: '' }]);
      message.success('Danh mục sách đã được thêm thành công.');
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const addBookRow = () => {
    setBookList([...bookList, { id: bookList.length + 1, barcode: '' }]);
  };

  const removeBookRow = (id: number) => {
    setBookList(bookList.filter((book) => book.id !== id));
  };

  const modalThem = (
    <Modal
      title="Thêm danh mục sách"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Tên sách" name={`BookName`}>
              <Input placeholder="Nhập tên sách" suffix={<SearchOutlined />} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vị trí"
              name={`LocationId`}
              rules={[{ required: true, message: 'Vui lòng chọn vị trí!' }]}
            >
              <Select>
                <Select.Option value="CVD">CVD</Select.Option>
                <Select.Option value="S11">S11</Select.Option>
                <Select.Option value="NO">NO</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {bookList.map((book, index) => (
          <div key={book.id} style={{ marginBottom: '10px', borderBottom: '1px solid #f0f0f0', paddingBottom: '10px' }}>
            <Row gutter={16} align={'bottom'}>
              <Col span={24}>
                <Form.Item
                  label={`Barcode sách ${index + 1}`}
                  name={`barcode-${book.id}`}
                  rules={[{ required: true, message: 'Vui lòng nhập Barcode!' }]}
                >
                  <Input
                    style={{ width: '100%' }}
                    value={book.barcode}
                    prefix={index + 1 + '.'}
                    suffix={
                      <CloseOutlined type="text" style={{ color: 'red' }} onClick={() => removeBookRow(book.id)} />
                    }
                    onChange={(e) => {
                      const newList = [...bookList];
                      newList[index].barcode = e.target.value;
                      setBookList(newList);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}

        <Form.Item style={{ marginTop: '20px' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Button icon={<PlusOutlined />} onClick={addBookRow} style={{ width: '100%', marginBottom: '20px' }} />
            </Col>
            <Col span={24}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
                Thêm vào danh mục
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Quản lý Sách/Thiết bị"
                  footer="Nhập sách vào kho"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm danh mục sách
                    </Button>,
                  ]}
                />
                <NhapSachTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Quản lý Sách/Thiết bị"
            footer="Nhập sách vào kho"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm danh mục sách
              </Button>,
            ]}
          />
          <NhapSachTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>NhapSach</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default NhapSach;
