import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TonKhoTable } from '@app/components/tables/BasicTable/TonKhoTable';
import './Pages.css';
const TonKho: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Tồn kho Sách/Thiết bị"
                  footer="Thông tin Tồn kho Sách/Thiết bị"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />}>
                      Thêm Tồn Kho
                    </Button>,
                  ]}
                />
                <TonKhoTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Tồn kho Sách/Thiết bị"
            footer="Thông tin Tồn kho Sách/Thiết bị"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />}>
                Thêm Tồn Kho
              </Button>,
            ]}
          />
          <TonKhoTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>TonKho</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default TonKho;
